import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/modules/auth/login.module.scss';
import { authActions } from '../../redux/slice/auth.slice';
import LoginForm from '../../components/modules/auth/LoginForm';
const Login = () => {
    const auth = useSelector(authActions.selectors.getLoggedInStatus);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            navigate('/');
        }
    }, [auth, navigate]);
    return (
        <Grid
            className={styles.loginBackground}
            container
        >
            <LoginForm />
        </Grid>
    )
}

export default Login
