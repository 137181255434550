import { put, takeEvery } from "redux-saga/effects";
import { addVisitServ, createSubscriptionServ, getDashboardDataServ, getVisitServ, getWelfareProcessServ, updateWelfareProcessServ } from "../services/shared.service";
import { sharedActions } from "../slice/shared.slice";
import { ADD_VISIT, CREATE_SUBSCRIPTION, GET_DASHBOARD_DATA, GET_VISITS, GET_WELFARE_PROCESS, UPDATE_WELFARE_PROCESS } from "../types/shared.types";
import toast from "react-hot-toast";

export function* getWelfareProcessSaga(action) {
    try {
        const welfareProcess = yield getWelfareProcessServ();
        yield put(sharedActions.actions.setWelfareProcessSlice(welfareProcess?.data));
    } catch (err) {
        console.log("Error fetching users:", err?.message);
    }
}


export function* updateWelfareProcessSaga(action) {
    try {
        const updatedWelfareProcess = yield updateWelfareProcessServ(action.payload);
        if (updatedWelfareProcess?.data) {
            yield put(sharedActions.actions.setWelfareProcessSlice(updatedWelfareProcess?.data));
            toast.success("Welfare Process updated successfully");
        }
    } catch (err) {
        toast.error("Something went wrong");
    }
}

export function* createSubscriptionSaga(action) {
    try {
        const createdSubscription = yield createSubscriptionServ(action.payload);
        if (createdSubscription?.data) {
            // yield put(sharedActions.actions.setWelfareProcessSlice(createdSubscription?.data));
            toast.success("Subscription Added Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
    }
}

export function* addVisitSaga(action) {
    try {
        const createdVisit = yield addVisitServ(action.payload);
        if (createdVisit?.data) {
            toast.success("Visit Added Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(err?.response?.data?.message);
    }
}

export function* getVisitSaga(action) {
    try {
        const visits = yield getVisitServ(action.payload);
        if (visits?.data) {
            yield put(sharedActions.actions.setVisits(visits?.data));
        }
        else {
            yield put(sharedActions.actions.setVisits([]));
        }
    } catch (err) {
        console.log("err", err);
        yield put(sharedActions.actions.setVisits([]));
        toast.error(err?.response?.data?.message);
    }
}

export function* getDashboardDataSaga() {
    try {
        const data = yield getDashboardDataServ();
        if (data?.data) {
            yield put(sharedActions.actions.setAnalytics(data?.data));
        }
        else {
            yield put(sharedActions.actions.setAnalytics({}));
        }
    } catch (err) {
        console.log("err", err);
        yield put(sharedActions.actions.setVisits({}));
        toast.error(err?.response?.data?.message);
    }
}

export function* watchSharedAsync() {
    yield takeEvery(GET_WELFARE_PROCESS, getWelfareProcessSaga);
    yield takeEvery(UPDATE_WELFARE_PROCESS, updateWelfareProcessSaga);
    yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
    yield takeEvery(ADD_VISIT, addVisitSaga);
    yield takeEvery(GET_VISITS, getVisitSaga);
    yield takeEvery(GET_DASHBOARD_DATA, getDashboardDataSaga);

}
