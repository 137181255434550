import {
  Logout,
  Menu
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import Clock from 'react-live-clock';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/logos/logo.png';
import { authActions } from "../../../redux/slice/auth.slice";
import { uiActions } from "../../../redux/slice/ui.slice";
import { USER_LOGOUT } from "../../../redux/types/auth.types";
import theme from "../theme/theme";
import { Drawer, DrawerHeader, menuItems } from "./helper";
import ProfileMenu from "./ProfileMenu";
import styles from "./sidebar.module.scss";

const drawerWidth = 240; // Width of the drawer when open

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selected = useSelector(uiActions.selectors.getSelectedNav);
  const user = useSelector(authActions.selectors.getCurrentUser)

  const handleButtonClick = (link) => {
    navigate(link);
  };

  const handleSelect = (value, link) => {
    dispatch(uiActions.actions.setSelectedNav(value));
    handleButtonClick(link);
  };

  const open = useSelector(uiActions.selectors.getSideBarOpen)
  const handleDrawerOpen = () => {
    dispatch(uiActions.actions.setSideBarOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(uiActions.actions.setSideBarOpen(false));
  };


  const handleLogout = () => {
    dispatch({ type: USER_LOGOUT });
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.secondary.main,
          transition: "margin 0.3s, width 0.3s",
          width: open ? `calc(100% - ${drawerWidth + 30}px)` : "100%",
          marginLeft: open ? `${drawerWidth}px` : 0,
        }}
      >
        <Toolbar className={styles.toolbarContainer}>
          <Typography
            variant="h6"
            color="primary"
            sx={{ ml: open ? 0 : 7 }}
          >
            {selected}
          </Typography>
          <Typography variant="h6" color="primary" >
            <Clock
              format={'ddd DD MMM YYYY hh:mm a'}
              ticking={true}
              timezone={'Europe/London'}
            />
          </Typography>
          <ProfileMenu user={user} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            sx={{ alignSelf: open ? "flex-start" : "center", gap: '10px' }}
            onClick={() => handleButtonClick("/")}
          >
            <img src={logo} alt="logo" width={40} height={40} />
            {open && <Typography textAlign={'center'} variant='h6' color={'secondary'}>Hyndburn Pantry </Typography>}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{ alignSelf: open ? "flex-end" : "center", ml: 0 }}
          >
            {open ? <ChevronLeftIcon color="white" /> : <Menu color="secondary" />}
          </IconButton>
        </DrawerHeader>
        <br />
        <List className={styles.listContainer}>
          <Box>
            {menuItems?.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  className={`${styles.itemButton}  ${selected === item?.label ? styles.selectedItem : null}`}
                  onClick={() => handleSelect(item?.label, item?.link)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: selected === item?.label ? "primary.main" : "secondary.main",
                    }}
                  >
                    {item.icon
                      ? item?.icon
                      : null}
                  </ListItemIcon>
                  <Typography
                    color={selected === item?.label ? "primary" : "secondary"}
                    sx={{ opacity: open ? 1 : 0 }}
                  >
                    {item?.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Box>
            <ListItem onClick={handleLogout} disablePadding>
              <ListItemButton className={`${styles.itemButton}`}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "secondary.main",
                  }}
                >
                  <Logout />
                </ListItemIcon>
                <Typography color={"secondary"} sx={{ opacity: open ? 1 : 0 }}>
                  Logout
                </Typography>
              </ListItemButton>
            </ListItem>
          </Box>
        </List>
      </Drawer>
      <Box
        component="main"
        className={styles.main}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
