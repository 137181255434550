import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../components/modules/Dashboard/dashboard.module.scss';
import { sharedActions } from '../../redux/slice/shared.slice';
import { uiActions } from '../../redux/slice/ui.slice';
import { GET_DASHBOARD_DATA } from '../../redux/types/shared.types';

// Import Material-UI icons
import BarChartIcon from '@mui/icons-material/BarChart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Dashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav("Dashboard"));
        dispatch({ type: GET_DASHBOARD_DATA });
    }, [dispatch]);

    const analytics = useSelector(sharedActions.selectors.getAnalytics);

    return (
        <div className={styles.dashboardContainer}>
            {analytics && (
                <>
                    {/* Trustees Count */}
                    <div className={`${styles.card} ${styles.gradientBlue}`}>
                        <div>
                            <div className={styles.cardValue}>
                                {analytics?.userStats?.trusteeCount}
                            </div>
                            <div className={styles.cardTitle}>Trustees</div>
                        </div>
                        <ShoppingCartIcon className={styles.cardIcon} />

                    </div>

                    {/* Volunteers Count */}
                    <div className={`${styles.card} ${styles.gradientGreen}`}>
                        <div>
                            <div className={styles.cardValue}>
                                {analytics?.userStats?.volunteerCount}
                            </div>
                            <div className={styles.cardTitle}>Volunteers</div>
                        </div>
                        <BarChartIcon className={styles.cardIcon} />

                    </div>

                    {/* Members Count */}
                    <div className={`${styles.card} ${styles.gradientYellow}`}>
                        <div>
                            <div className={styles.cardValue}>
                                {analytics?.userStats?.memberCounts}
                            </div>
                            <div className={styles.cardTitle}>Members</div>
                        </div>
                        <PersonAddIcon className={styles.cardIcon} />

                    </div>

                    {/* Total Visits Count */}
                    <div className={`${styles.card} ${styles.gradientGreen}`}>
                        <div>
                            <div className={styles.cardValue}>
                                {analytics?.visitStats?.totalCount}
                            </div>
                            <div className={styles.cardTitle}>Visits This Week</div>
                        </div>
                        <BarChartIcon className={styles.cardIcon} />

                    </div>

                    {/* Total Amount Collected */}
                    {/* <div className={`${styles.card} ${styles.gradientRed}`}>
                        <div>
                            <div className={styles.cardValue}>
                                £{analytics?.visitStats?.totalAmount}
                            </div>
                            <div className={styles.cardTitle}>Amount Collected This Week</div>
                        </div>
                        <PieChartIcon className={styles.cardIcon} />

                    </div> */}
                </>
            )}
        </div>
    );
};

export default Dashboard;
