import { all } from 'redux-saga/effects'
import { watchAuthAsync } from './auth.saga'
import { watchUserAsync } from './user.saga'
import { watchMemberAsync } from './member.saga'
import { watchSharedAsync } from './shared.saga'

export function* rootSaga() {
  yield all([
    watchAuthAsync(),
    watchUserAsync(),
    watchMemberAsync(),
    watchSharedAsync(),
  ])
}
