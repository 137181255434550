import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { memo, useEffect, useState } from "react";
import { myTableStyles } from './helper';
import styles from './myTable.module.scss';
import TableSkeleton from "./TableSkeleton";
import { uiActions } from '../../../redux/slice/ui.slice';
import { useSelector } from 'react-redux';

const MyTable = ({
  checkboxSelection,
  rows,
  columns,
  handleSelectedRows = () => { },
  id,
}) => {
  const [sortModel, setSortModel] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const isSideBarOpen = useSelector(uiActions.selectors.getSideBarOpen)

  useEffect(() => {
    if (rows.length === 0) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Clean up the timer on unmount
    } else {
      setLoading(false);
    }
  }, [rows]);

  return (
    <Box sx={{
      height: '800px',
      width: {
        xs: '100%',
        sm: isSideBarOpen ? '62.8vw' : '85vw',
        md: isSideBarOpen ? '72vw' : '95vw',
        lg: isSideBarOpen ? '72vw' : '88vw',
        lgPlus: isSideBarOpen ? '76vw' : '100%',
        xlMinus: isSideBarOpen ? '76vw' : '100%',
        xl: isSideBarOpen ? '79vw' : '100%',
      },
    }} >
      {loading ? (
        <TableSkeleton rows={4} cols={columns?.length} />
      ) : (
        rows?.length ? (
          <DataGrid
            rows={rows}
            getRowId={id}
            columns={columns}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onRowSelectionModelChange={(ids) => {
              handleSelectedRows(ids);
            }}
            sx={myTableStyles}
          />
        ) : (
          <Box className={styles.noData}><ContentPasteOffIcon /> No Data</Box>
        )
      )}
    </Box>
  );
};

export default memo(MyTable);
