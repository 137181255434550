export const generateCsv = (rows, columns) => {
    const headers = columns.map((col) => col.headerName).join(",");
    const dataRows = rows.map((row) =>
        columns.map((col) => row[col.field]).join(",")
    );
    return [headers, ...dataRows].join("\n");
};

export const handleDownloadCsv = (rows, columns, name) => {
    const csvContent = generateCsv(rows, columns);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};