import { Tab, Tabs } from "@mui/material";
import React from "react";
import styles from "./navtabs.module.scss";
const CustomNavTabs = ({ selectedTab, handleSelectedTab, navitems }) => {
  const handleChange = (event, newValue) => {
    handleSelectedTab(newValue);
  };

  return (
    <div className={styles.tabContainer}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="standard"
        className={styles.indicator}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {navitems.map((item, index) => (
          <Tab
            key={index}
            label={item?.label}
            className={selectedTab === index ? styles.selectedTab : styles.tab}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default CustomNavTabs;
