import { Box, Button } from '@mui/material';
import React from 'react';

const SignatureUpload = ({ handleSignaturePadOpen, handleSignatureSubmit, type }) => {

    const handleFileChange = (event, onChange) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log("type", type);
                handleSignatureSubmit(type, reader.result, file)
            };
            reader.readAsDataURL(file);
        } else {
            handleSignatureSubmit(type, null, null)

        }
    };


    return (
        <Box width={'70%'} display={'flex'} alignItems={'center'} gap={4} justifyContent={'space-between'}>
            <Button fullWidth onClick={() => handleSignaturePadOpen(type)} variant='contained'>Draw Signature</Button>
            <form style={{ width: '100%' }}>
                <input
                    accept="image/*"
                    id={`contained-button-file-${type}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileChange(event)}
                />
                <label style={{ width: '100%' }} htmlFor={`contained-button-file-${type}`}>
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        fullWidth
                    >
                        Select Signature
                    </Button>
                </label>
            </form>

        </Box>
    )
}

export default SignatureUpload
