import { EmailOutlined } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import logo from '../../../assets/logos/logo.png';
import InputField from '../../shared/RHF/InputField';
import theme from '../../shared/theme/theme';
import { YupUserSchema, formUserSchema } from './helper';
import styles from './login.module.scss';
import { USER_LOGIN } from '../../../redux/types/auth.types';
const LoginForm = () => {

    const dispatch = useDispatch()
    let userSchema = object(YupUserSchema);
    const { control, handleSubmit, formState: { errors, isValid } } = useForm(formUserSchema(userSchema))

    const handleLogin = (data) => {
        console.log("data", data)
        dispatch({ type: USER_LOGIN, payload: data })
    }

    return (
        <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card sx={{ backgroundColor: theme.palette.background.default }}>
                <CardContent className={styles.container} >
                    <img src={logo} alt="logo" className={styles.logo} />
                    <Typography textAlign={'center'} variant='h6' color={'text.primary'}>Welcome to
                        Hyndburn Pantry </Typography>
                    <form className={styles.form} onSubmit={handleSubmit(handleLogin)}>
                        <InputField control={control} errors={errors} name={'email'} placeHolder={'Email'} label={"Email"} type={'text'} endIcon={<EmailOutlined />} />
                        <InputField control={control} errors={errors} name={'password'} placeHolder={'Password'} label={"Password"} type={'password'} />
                        <Button disabled={!isValid} variant="contained" color="primary" sx={{ color: "seconday" }} fullWidth type="submit">
                            Login
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LoginForm
