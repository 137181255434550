import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth.slice";
import ui from "./ui.slice";
import user from "./user.slice";
import shared from "./shared.slice";

const reducers = combineReducers({
  [auth.name]: auth.reducer,
  [ui.name]: ui.reducer,
  [user.name]: user.reducer,
  [shared.name]: shared.reducer
});

export default reducers;
