import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

export default function MyDialog({ open, handleClose, title, maxWidth = "md", isFullScreen = false, children }) {
    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                open={open}
                fullWidth
                fullScreen={isFullScreen}
                maxWidth={maxWidth}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} >
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {children}
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}
