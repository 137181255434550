import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "../../pages/auth/Login";
import Dashboard from "../../pages/dashboard/Dashboard";
import Reporting from "../../pages/dashboard/Reporting";
import UserManagement from "../../pages/dashboard/UserManagement";
import WelfareProcess from "../../pages/welfareProcess/WelfareProcess";
import PrivateLayoutRoutes from "./PrivateLayoutRoutes";

const MyRouter = () => {


  return (
    <Router>
      <Routes>
        <Route element={<PrivateLayoutRoutes />}>

          <Route path="/" element={<Dashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/reporting" element={<Reporting />} />
          <Route path="/welfare-process" element={<WelfareProcess />} />
          {/* <Route element={<AllowedRoutes allowedRoles={[ROLES.sAdmin]} />}>
            <Route path="user-list" element={<UsersList />} />
          </Route> */}

          {/* <Route
            element={
              <AllowedRoutes allowedRoles={[ROLES.sAdmin, ROLES.Operator]} />
            }
          >

          </Route> */}



        </Route>

        <Route path="/login" element={<Login />} />


      </Routes>
    </Router>
  );
};

export default MyRouter;
