import theme from "../theme/theme";

export const myTableStyles = {
    backgroundColor: theme.palette.gray.main,
    '& .MuiDataGrid-container--top [role=row]': {
        background: theme.palette.primary.main,
        color: theme.palette.gray.main,
    },
    "& .MuiDataGrid-sortIcon": {
        color: theme.palette.gray.main,
    },
    "& .MuiDataGrid-menuIconButton": {
        color: theme.palette.gray.main
    },
    '.MuiDataGrid-columnSeparator': {
        color: theme.palette.gray.main,
    },
    '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: theme.palette.gray.light
    }
}