import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";

const RHFRadioGroup = ({
    control,
    errors,
    name,
    label,
    required = false
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    component="fieldset"
                    error={!!(errors && errors?.[name] && errors?.[name].message)}
                    required={required}
                >
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup {...field} row>
                        <FormControlLabel
                            value="yes"
                            control={<Radio size="small" />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio size="small" />}
                            label="No"
                        />
                    </RadioGroup>
                    <FormHelperText>
                        {errors && errors?.[name] && errors?.[name].message}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

export default RHFRadioGroup;
