export const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }],
        [{ 'color': [] }, { 'background': [] }], // Color and background
        [{ 'size': ['small', false, 'large', 'huge'] }],
    ],
};

export const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'align', 'color', 'background',
];

export const normalizeContent = (content) => {
    return content
        // Remove empty <p> tags
        .replace(/<p><br><\/p>/g, '')
        // Replace multiple line breaks with a single line break
        .replace(/(\r\n|\n|\r){2,}/g, '\n');
};
