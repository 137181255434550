import { Add, Launch, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import styles from './user.module.scss';
const CustomToolbar = ({
  searchTerm,
  handleSearch,
  text,
  isDisabled = false,
  handleClickBtn1,
  handleClickBtn2,
  placeHolder,
}) => {

  return (
    <Grid container gap={'10px'} alignItems="center" justifyContent="space-between">
      <Grid item xs={12} md={6} >
        <TextField
          label={placeHolder}
          size="small"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchBar}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'} item xs={12} md={5} >

        {text &&
          <Button
            sx={{ p: 1 }}
            onClick={handleClickBtn1}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<Add />}
            disabled={isDisabled}
          >
            {text}
          </Button>
        }
        <Button
          sx={{ p: 1 }}
          onClick={handleClickBtn2}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<Launch />}
          disabled={isDisabled}
        >
          Export CSV
        </Button>
      </Grid>

    </Grid>
  );
};

export default CustomToolbar;
