import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  welfareProcess: "",
  cardState: 'all',
  visits: [],
  analytics: {}
};

const name = "shared";
const parentSelector = (state) => state?.[name];

const sharedSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setWelfareProcessSlice: (state, action) => {
      return {
        ...state,
        welfareProcess: action.payload,
      };
    },
    setCardState: (state, action) => {
      return {
        ...state,
        cardState: action.payload,
      };
    },
    setVisits: (state, action) => {
      return {
        ...state,
        visits: action.payload,
      };
    },
    setAnalytics: (state, action) => {
      return {
        ...state,
        analytics: action.payload,
      };
    },
  },
});


const getWelfareProcess = createSelector(parentSelector, (state) => {
  return state?.welfareProcess;
});

const getCardState = createSelector(parentSelector, state => {
  return state?.cardState
});

const getVisits = createSelector(parentSelector, state => {
  return state?.visits
})

const getAnalytics = createSelector(parentSelector, state => {
  return state?.analytics
})

const actions = {
  ...sharedSlice.actions,
};

const selectors = {
  getWelfareProcess,
  getCardState,
  getVisits,
  getAnalytics
};

export const sharedActions = { actions, selectors };

export default sharedSlice;
