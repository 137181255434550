import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.css';
import MyRouter from './components/routes/MyRouter';
import theme from './components/shared/theme/theme';
import { persistor, store } from './redux/store';
import AppBoot from './components/shared/AppBoot';

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBoot />
          <Toaster />
          <MyRouter />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
