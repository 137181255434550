import dayjs from "dayjs";

export const formatDate = (date = null) => {
    const format = "DD/MM/YYYY"; // UK date format
    return date ? dayjs(date).format(format) : dayjs().format(format);
}

export const formatDateTime = (date = null) => {
    const format = "DD/MM/YYYY HH:mm a";
    return date ? dayjs(date).format(format) : dayjs().format(format);
}

export const formatPaidDate = (date = null) => {
    const format = "DD/MM/YYYY HH:mm a";
    return date ? dayjs(date).format(format) : "-";
};