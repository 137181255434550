import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

const TableSkeleton = ({rows , cols}) => {
    return (
        <Table aria-label="skeleton table">
            <TableBody>
                {[...Array(rows)].map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {[...Array(cols)].map((_, colIndex) => (
                            <TableCell key={colIndex}>
                                <Skeleton  variant="rounded" height={40} animation="wave" />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TableSkeleton;
