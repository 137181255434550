import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import styles from './welfareProcess.module.scss'; // Import modular SCSS
import MyDialog from '../../components/shared/MyDialog/MyDialog';
import WelfareTextEditor from './WelfareTextEditor';
import { normalizeContent } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import { GET_WELFARE_PROCESS, UPDATE_WELFARE_PROCESS } from '../../redux/types/shared.types';
import { sharedActions } from '../../redux/slice/shared.slice';
import { formatDateTime } from '../../components/utils/date';
import { authActions } from '../../redux/slice/auth.slice';
import { USER_ROLES } from '../../components/utils/enums';

const WelfareProcess = () => {
    const [isModalOpen, setIsModelOpen] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: GET_WELFARE_PROCESS })
    }, [dispatch])

    const welfareProcess = useSelector(sharedActions.selectors.getWelfareProcess);

    console.log("welfareProcess", welfareProcess)
    const [tempContent, setTempContent] = useState('');

    const handleModelOpen = () => {
        const normalizedContent = normalizeContent(welfareProcess?.content);
        setTempContent(normalizedContent); // Sync the temp content with the parent state when opening
        setIsModelOpen(true);
    };

    const handleModelClose = () => {
        setIsModelOpen(false);
    };

    const handleSaveContent = () => {
        const normalizedContent = normalizeContent(tempContent);
        const payload = {
            content: normalizedContent,
            welfareId: welfareProcess?._id
        }
        dispatch({ type: UPDATE_WELFARE_PROCESS, payload })
        setIsModelOpen(false); // Close the modal after saving
    };


    const handlePrint = () => {
        const contentToPrint = document.getElementById('printableContent').innerHTML;
        const printWindow = window.open('', '', 'height=500, width=800');
        printWindow.document.write('<html><head><title>Print Content</title>');
        printWindow.document.write('<style>body{font-family: Arial, sans-serif; padding: 20px;} .content {border: 1px solid #ddd; padding: 10px;}</style>');
        printWindow.document.write('</head><body>');
        printWindow.document.write(contentToPrint);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };


    const currentUser = useSelector(authActions.selectors.getCurrentUser)

    return (
        <>
            <div className={styles.card}>
                <div className={styles.header}>
                    <Box display={'flex'} alignItems={'center'} gap={"20px"}>
                        <Typography variant='body1'><b> Last update at :</b> {formatDateTime(welfareProcess?.updatedAt)}</Typography>
                        <Typography variant='body1'><b>Last updated By :</b> {welfareProcess?.editiedBy?.firstName} {" "} {welfareProcess?.editiedBy?.lastName}</Typography>
                    </Box>
                    <Box>
                        {(currentUser?.role === USER_ROLES.S_ADMIN || currentUser?.role === USER_ROLES.TRUSTEE) &&
                            <IconButton onClick={handleModelOpen} className={styles.editButton}>
                                <EditIcon />
                            </IconButton>
                        }
                        <IconButton onClick={handlePrint} className={styles.printButton}>
                            <PrintIcon />
                        </IconButton>
                    </Box>
                </div>
                <div id="printableContent" className={styles.content}>
                    <div dangerouslySetInnerHTML={{ __html: welfareProcess?.content }} />
                </div>
            </div>
            <br />

            <MyDialog title="Edit Content" open={isModalOpen} handleClose={handleModelClose}>
                <WelfareTextEditor
                    editorContent={tempContent}
                    setEditorContent={setTempContent}
                    handleSave={handleSaveContent}
                />
            </MyDialog>
        </>
    );
};

export default WelfareProcess;
