import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";

export const YupVolunteerSchema = (isEditMode) => {
    return {
        firstName: string().required(),
        lastName: string().required(),
        email: string().email('Invalid email format').required('Email is required'),
        password: isEditMode ? string().notRequired() : string().required('Password is required'),
        mobileNum: string()
            .matches(/^[0-9+-]*$/, 'Mobile number can only contain digits, +, and -')
            .notRequired(),
    }
}

export const formVolunteerSchema = (volunteerSchema) => {
    return {
        resolver: yupResolver(volunteerSchema),
        mode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            mobileNum: '',
            email: '',
            password: '',
            address: '',
            postCode: '',
        }
    }
}
