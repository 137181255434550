import apiInterceptor from "./Interceptors/ApiInterceptor";


export const getWelfareProcessServ = async () => {
    try {
        const response = await apiInterceptor.get(`/welfare/get-welfare-process`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUserServ = async (userId) => {
    try {
        const response = await apiInterceptor.delete(`/auth/delete-user/${userId}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};


export const updateWelfareProcessServ = async (payload) => {
    try {
        const response = await apiInterceptor.put(`/welfare/update-welfare-process`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};


export const createSubscriptionServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/subscription/add-subscription`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const addVisitServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/visit/add-visit`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getVisitServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/visit/get-visits`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};


export const getDashboardDataServ = async () => {
    try {
        const response = await apiInterceptor.get(`/analytics/get-dashboard-data`);
        return response?.data;
    } catch (error) {
        throw error;
    }
};