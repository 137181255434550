import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { boolean, date, mixed, number, string } from "yup";

export const YupMemberSchema = (isEditMode) => {
    return {
        surnameMember: string().notRequired(),
        forenameMember: string().notRequired(),
        email: string().email('Invalid email format').notRequired(),
        mobileNum: string()
            .matches(/^[0-9+-]*$/, 'Mobile number can only contain digits, +, and -')
            .notRequired(),
        memberShipPaidDate: date().notRequired().typeError('Invalid date format'),
        addressChecked: boolean().notRequired(),
        userActive: boolean().notRequired(),
        proofOfId: boolean().notRequired(),
        cardNumber: string().notRequired(),
        totalNumberOfPeople: number().required(),
        allergyDetails: string().required(),
        signatureOfShopper: mixed().notRequired(),
        signatureOfApplicant: mixed().notRequired(),
        photo1: mixed().notRequired(),
        photo2: mixed().notRequired(),
        photo3: mixed().notRequired(),
        photo4: mixed().notRequired(),
    }
}

export const formMemberSchema = (memberSchema) => {
    return {
        resolver: yupResolver(memberSchema),
        mode: 'onChange',
        defaultValues: {
            surnameMember: '',
            forenameMember: '',
            surnameShopper: '',
            forenameShopper: '',
            mobileNum: '',
            email: '',
            dobMember: null,
            memberShipPaidDate: dayjs(),
            addressChecked: false,
            address: '',
            postCode: '',
            isPropertyHouse: 'no',
            proofOfId: false,
            cardNumber: '',
            dobsAbove16: Array(8).fill(null),
            dobUnder16: Array(8).fill(null),
            totalNumberOfPeople: '',
            allergyDetails: '',
            totalNumberOfPets: '',
            signatureOfShopper: '',
            signatureOfApplicant: '',
            photo1: null,
            photo2: null,
            photo3: null,
            photo4: null,
        }
    }
}


export const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};
