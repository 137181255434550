import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { authActions } from '../../redux/slice/auth.slice'
import Layout from './Layout'


const PrivateLayoutRoutes = () => {
  const dispatch = useDispatch();
  const auth = useSelector(authActions.selectors.getLoggedInStatus)

  // useEffect(() => {
  //   const auth = Cookies.get('accessToken');
  //   if (!auth) {
  //     dispatch(authActions.actions.loginUserStatus(false))
  //   }
  // }, [auth, dispatch]);

  return (
    auth ?
      <>
        <Layout>
          <Outlet />
        </Layout>
      </>
      : <Navigate to='/login' />
  )
}

export default PrivateLayoutRoutes;