import styled from "@emotion/styled";
import { Dashboard, FoodBank, ManageAccounts, NoteAlt, TurnSharpLeft } from "@mui/icons-material";
import MuiDrawer from '@mui/material/Drawer';

export const openedMixin = (theme) => ({
    width: 270,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    border: 'none',
    overflowX: 'hidden',
    backgroundColor: theme.palette.primary.main
});

export const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    width: '70px',
    [theme.breakpoints.up('sm')]: {
        width: '70px',
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 13px',
}));


export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: 240,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const menuItems = [
    { label: "Dashboard", link: "/", icon: <Dashboard /> },
    { label: "User Management", link: "/user-management", icon: <ManageAccounts /> },
    { label: "Reporting", link: "/reporting", icon: <NoteAlt /> },
    { label: "Welfare Proccess", link: "/welfare-process", icon: <FoodBank /> },

];