import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../../../redux/slice/user.slice'
import { DELETE_USER, GET_USERS } from '../../../../redux/types/user.types'
import MyDialog from '../../../shared/MyDialog/MyDialog'
import MyTable from '../../../shared/MyTable/MyTable'
import { handleDownloadCsv } from '../../../utils/csv'
import { USER_ROLES } from '../../../utils/enums'
import styles from '../../user/user.module.scss'
import CustomToolbar from '../CustomToolbar'
import GenericUserForm from '../GenericUserForm'
import { getGenericUserColumns } from '../helper'
const Trustee = () => {

    const [menuState, setMenuState] = useState({
        anchorEl: null,
        selectedRow: null,
    });

    const [editUserData, setEditUserData] = useState({})

    const handleOpenMenu = (event, row) => {
        setMenuState({
            anchorEl: event.currentTarget,
            selectedRow: row,
        });
    };

    const handleClose = () => {
        setMenuState({
            anchorEl: null,
            selectedRow: null,
        });
    };


    const handleClickItem = (key, row) => {
        // eslint-disable-next-line default-case
        switch (key) {
            case "Delete":
                const payload = {
                    userId: row?._id,
                };
                dispatch({ type: DELETE_USER, payload });
                break;
            case "Edit":
                setEditUserData(row);
                handleModelOpen()
        }
        handleClose(); // Close the menu after the action is handled
    };


    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        const payload = {
            role: USER_ROLES.TRUSTEE,
            searchTerm: value
        }
        dispatch({ type: GET_USERS, payload })
    };

    const [isModalOpen, setIsModelOpen] = useState(false);

    const handleModelOpen = () => {
        setIsModelOpen(true)
    }

    const handleModelClose = () => {
        setIsModelOpen(false)
        setEditUserData({})
    }

    const dispatch = useDispatch();
    useEffect(() => {
        const payload = {
            role: USER_ROLES.TRUSTEE,
            searchTerm: ""
        }
        dispatch({ type: GET_USERS, payload })
    }, [dispatch])

    const trustees = useSelector(userActions.selectors.getTrustees);

    const genericUserColumns = getGenericUserColumns({ menuState, handleClose, handleClickItem, handleOpenMenu })

    return (
        <>
            <Grid container className={styles.userContainer}>
                <Grid item xs={12}>
                    <CustomToolbar
                        searchTerm={searchTerm}
                        placeHolder={"Search Trustee"}
                        handleSearch={handleSearch}
                        text={"Add Trustee"}
                        handleClickBtn1={handleModelOpen}
                        link={"/add-inheritor"}
                        handleClickBtn2={() => handleDownloadCsv(trustees, genericUserColumns, `Trustees ${dayjs().format("DD_MM_YYYY")}`)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MyTable
                        checkboxSelection={false}
                        columns={genericUserColumns}
                        rows={trustees}
                        id={(row) => row?._id}
                    />
                </Grid>
                <MyDialog title={"Add Trustee"} open={isModalOpen} handleClose={handleModelClose} >
                    <GenericUserForm editUserData={editUserData} type={USER_ROLES.TRUSTEE} handleClose={handleModelClose} />
                </MyDialog>
                {console.log("trustees", trustees)}
            </Grid>
        </>
    )
}

export default Trustee
