import axios from 'axios';
import Cookies from 'js-cookie'; // Import Cookies for handling cookies
import { authActions } from '../../slice/auth.slice';
import { store } from '../../store';
import toast from 'react-hot-toast';

const apiInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor for public requests
apiInterceptor.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a request interceptor for authenticated requests
apiInterceptor.interceptors.request.use(
    function (config) {
        // Get the token from cookies
        const token = Cookies.get('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;  // Set the Authorization header
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

apiInterceptor.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response && error?.response?.status === 401) {
            toast.error("Session expired, please log in again.");
            store.dispatch(authActions.actions.resetAuth()); // This will log the user out
            Cookies.remove('accessToken'); // Remove the expired token
        }
        return Promise.reject(error);
    }
);


export default apiInterceptor;
