import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";

const RHFCheckBox = ({
    control,
    errors,
    name,
    label,
    disabled = false,
    required = false
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    error={!!(errors && errors?.[name] && errors?.[name].message)}
                    required={required}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                checked={field.value}
                                disabled={disabled}
                                color="primary"
                                size="small"
                            />
                        }
                        label={label}
                    />
                    <FormHelperText>
                        {errors && errors?.[name] && errors?.[name].message}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};

export default RHFCheckBox;
