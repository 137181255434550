import toast from "react-hot-toast";
import { put, select, takeEvery } from "redux-saga/effects";
import { USER_ROLES } from "../../components/utils/enums";
import { createUserServ, deleteUserServ, getServedUserServ, getUserServ, updateUserServ } from "../services/user.service";
import { userActions } from "../slice/user.slice";
import { CREATE_USER, DELETE_USER, GET_SERVED_USERS, GET_USERS, UPDATE_USER } from "../types/user.types";

export function* createUserSaga(action) {
    try {
        const user = yield createUserServ(action.payload);

        if (user?.data) {
            if (user?.data?.role === USER_ROLES.TRUSTEE) {
                const prevTrustees = yield select(userActions.selectors.getTrustees);
                yield put(userActions.actions.setTrusteesSlice([user?.data, ...prevTrustees,]));
            } else {
                const prevVolunteers = yield select(userActions.selectors.getVolunteers);
                yield put(userActions.actions.setVolunteersSlice([user?.data, ...prevVolunteers,]));
            }

            toast.success("User Created successful");
        } else {
            toast.error("Something Went Wrong");
        }
    } catch (err) {
        toast.error("Something Went Wrong");
    }
}

export function* getUserSaga(action) {
    try {
        const users = yield getUserServ(action.payload);
        const userData = users?.data || [];

        const setUserAction = action.payload.role === USER_ROLES.TRUSTEE
            ? userActions.actions.setTrusteesSlice
            : userActions.actions.setVolunteersSlice;

        yield put(setUserAction(userData));
    } catch (err) {
        console.log("Error fetching users:", err?.message);
    }
}

export function* getServedUserSaga(action) {
    try {
        const users = yield getServedUserServ(action.payload);
        console.log("user", users)
        yield put(userActions.actions.setServedUsersSlice(users?.data));
    } catch (err) {
        console.log("Error fetching users:", err?.message);
    }
}


export function* deleteUserSaga(action) {
    try {
        const user = yield deleteUserServ(action.payload?.userId);
        if (user?.data) {
            toast.success("User Deleted Successfully");
            if (user?.data?.role === USER_ROLES.TRUSTEE) {
                const prevTrustees = yield select(userActions.selectors.getTrustees);
                const updatedTrustees = prevTrustees.filter(t => t._id !== user.data._id);
                yield put(userActions.actions.setTrusteesSlice(updatedTrustees));
            } else {
                const prevVolunteers = yield select(userActions.selectors.getVolunteers);
                const updatedVolunteers = prevVolunteers.filter(v => v._id !== user.data._id);
                yield put(userActions.actions.setVolunteersSlice(updatedVolunteers));
            }
        }

    } catch (err) {
        console.log("Error deleting user:", err?.message);
    }
}

export function* updateUserSaga(action) {
    try {
        const updatedUser = yield updateUserServ(action.payload);
        console.log("updated", updatedUser);
        if (updatedUser?.data) {
            if (updatedUser?.data?.role === USER_ROLES.TRUSTEE) {
                const prevTrustees = yield select(userActions.selectors.getTrustees);
                console.log("prevTrustees", prevTrustees)
                const updatedTrustees = prevTrustees.map(trustee =>
                    trustee._id === updatedUser.data._id ? updatedUser.data : trustee
                );
                yield put(userActions.actions.setTrusteesSlice(updatedTrustees));
            } else {
                const prevVolunteers = yield select(userActions.selectors.getVolunteers);
                const updatedVolunteers = prevVolunteers.map(volunteer =>
                    volunteer.id === updatedUser.data.id ? updatedUser.data : volunteer
                );
                yield put(userActions.actions.setVolunteersSlice(updatedVolunteers));
            }

            toast.success("User updated successfully");
        } else {
            toast.error("Something went wrong");
        }
    } catch (err) {
        toast.error("Something went wrong");
    }
}


export function* watchUserAsync() {
    yield takeEvery(CREATE_USER, createUserSaga);
    yield takeEvery(GET_USERS, getUserSaga);
    yield takeEvery(DELETE_USER, deleteUserSaga);
    yield takeEvery(UPDATE_USER, updateUserSaga);
    yield takeEvery(GET_SERVED_USERS, getServedUserSaga);
}
