import { Launch, Search } from "@mui/icons-material";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import styles from '../user/user.module.scss';

const VisitToolbar = ({
    searchTerm,
    handleSearch,
    text,
    isDisabled = false,
    handleClickBtn2,
    placeHolder,
    handleTime
}) => {
    return (
        <Grid container gap={'10px'} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={4}>
                <TextField
                    label={placeHolder}
                    size="small"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearch}
                    className={styles.searchBar}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} item xs={12} md={7}>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['day']}
                            size="small"
                            label="From"
                            format="DD/MM/YYYY"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        backgroundColor: '#FFFFFF', // White background
                                        "& .MuiInputBase-input": {
                                            color: '#000000', // Black text for the input
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#000000', // Black color for the label
                                        },
                                    },
                                },
                            }}
                            onChange={(date) => handleTime("from", date)}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['day']}
                            size="small"
                            label="To"
                            format="DD/MM/YYYY"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        backgroundColor: '#FFFFFF', // White background
                                        "& .MuiInputBase-input": {
                                            color: '#000000', // Black text for the input
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#000000', // Black color for the label
                                        },
                                    },
                                },
                            }}
                            onChange={(date) => handleTime("to", date)}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={4}>
                    <Button
                        sx={{ p: 1 }}
                        onClick={handleClickBtn2}
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<Launch />}
                        disabled={isDisabled}
                    >
                        Export CSV
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VisitToolbar;
