import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1f1f1f',
        },
        secondary: {
            main: '#f0fc6c',
        },
        gray: {
            main: '#f3f4f6',
            light: '#e9e9e9',
            dark: '#6e6e6e'
        },
        white: {
            main: '#FFFFFF',
        },
        error: {
            main: '#DF1D5A',
        },
        success: {
            main: '#55BE38',
        },
        background: {
            default: '#f3f4f6',
            paper: '#f3f4f6',
        },
        text: {
            primary: '#1f1f1f',
        },
    },
    typography: {
        allVariants: {
            fontFamily: 'poppins',
            textTransform: 'none',
        },

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            lgPlus: 1300,
            xlMinus: 1400,
            desktopLarge: 1500,
            xl: 1536,
        },
    },
});

export default theme;
