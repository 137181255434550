import apiInterceptor from "./Interceptors/ApiInterceptor";

export const loginServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(`/auth/login`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};







