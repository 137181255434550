import { FormControl, FormHelperText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { Controller } from 'react-hook-form';

const RHFDatePicker = ({ control, errors, name, label, views = ['day'], disableFuture = false }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    fullWidth
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={label}
                            {...field}
                            views={views}
                            disableFuture={disableFuture}
                            format='DD/MM/YYYY'
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        backgroundColor: "#FFFFFF", // Pure white background
                                        "& .MuiOutlinedInput-root": {
                                            color: "#000000", // Full black text color
                                            "& fieldset": {
                                                borderColor: "#000000", // Black border color for visibility
                                                borderWidth: "1px", // Set border width to 1px
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#000000", // Black border on hover
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#000000", // Black border when focused
                                            },
                                            "& input": {
                                                color: "#000000", // Ensure input text is black
                                            }
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "#000000", // Set label text color to black
                                        },
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <FormHelperText>{errors[name] && errors[name].message}</FormHelperText>
                </FormControl>
            )}
        />
    );
};

export default RHFDatePicker;
