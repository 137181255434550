import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { date, number } from "yup";

export const YupSubscriptionSchema = () => {
    return {
        subscriptionAmount: number().required(),
        paidDate: date().required('Payment Date is required').typeError('Invalid date format'),
    }
}

export const formSubscriptionSchema = (memberSchema) => {
    return {
        resolver: yupResolver(memberSchema),
        mode: 'onChange',
        defaultValues: {
            subscriptionAmount: '',
            paidDate: dayjs(),
            notes: ''
        }
    }
}
