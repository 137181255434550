import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import styles from "./sidebar.module.scss";

export default function ProfileMenu({ user }) {


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  return (
    <React.Fragment>
      <Box onClick={handleClick} className={styles.profileButton}>
        <Tooltip title="Profile settings">
          <IconButton
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* <Avatar
              alt={`profile img`}
              className={styles.profileAvatar}
            /> */}
          </IconButton>
        </Tooltip>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <Typography color="primary" className={styles.nameHeading}>{user?.firstName} {" "} {user?.lastName}</Typography>
          <Typography color="primary" className={styles.roleHeading}>{user?.role}</Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
