import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from './helper';
import { Button, Grid } from '@mui/material';
import { Save } from '@mui/icons-material';

const WelfareTextEditor = ({ editorContent, setEditorContent, handleSave }) => {

    const handleEditorChange = (content) => {
        setEditorContent(content); // Only update the temp content in the child
    };

    return (
        <>
            <ReactQuill
                value={editorContent}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                placeholder="Compose your text here..."
            />
            <br />
            <Grid display={'flex'} justifyContent={'flex-end'} item xs={12}>
                <Button
                    sx={{ p: 1, px: 3 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={handleSave} // Call the parent save function
                >
                    Save Welfare Process
                </Button>
            </Grid>
        </>
    );
};

export default WelfareTextEditor;
