import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  trustees: [],
  volunteers: [],
  members: [],
  welfareProcess: "",
  selectedMember: "",
  servedUsers: [],
  editMemberData: {}
};

const name = "user";
const parentSelector = (state) => state?.[name];

const userSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    resetUser: (state, action) => {
      return {
        ...state,
        trustees: []
      };
    },
    setTrusteesSlice: (state, action) => {
      return {
        ...state,
        trustees: action.payload,
      };
    },
    setVolunteersSlice: (state, action) => {
      return {
        ...state,
        volunteers: action.payload,
      };
    },
    setMembersSlice: (state, action) => {
      return {
        ...state,
        members: action.payload,
      };
    },
    setSelectedMemberSlice: (state, action) => {
      return {
        ...state,
        selectedMember: action.payload,
      };
    },
    setServedUsersSlice: (state, action) => {
      return {
        ...state,
        servedUsers: action.payload,
      };
    },
    setEditMemberDataSlice: (state, action) => {
      return {
        ...state,
        editMemberData: action.payload,
      };
    },
  },
});

const getTrustees = createSelector(parentSelector, (state) => {
  return state?.trustees;
});

const getVolunteers = createSelector(parentSelector, (state) => {
  return state?.volunteers;
});

const getMembers = createSelector(parentSelector, (state) => {
  return state?.members;
});

const getSelectedMember = createSelector(parentSelector, (state) => {
  return state?.selectedMember;
});

const getServedUsers = createSelector(parentSelector, (state) => {
  return state?.servedUsers;
});

const getEditMemberData = createSelector(parentSelector, (state) => {
  return state?.editMemberData;
});

const actions = {
  ...userSlice.actions,
};

const selectors = {
  getTrustees,
  getVolunteers,
  getMembers,
  getSelectedMember,
  getServedUsers,
  getEditMemberData
};

export const userActions = { actions, selectors };

export default userSlice;
