import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";


export const YupUserSchema = {
    email: string().email('Invalid email format').required('Email is required'),
    password: string().required()
}
export const formUserSchema = (userSchema) => {
    return {
        resolver: yupResolver(userSchema),
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: ''
        }
    }
}
