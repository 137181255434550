import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText, TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import theme from "../theme/theme";

const InputField = ({
  control,
  errors,
  name,
  placeHolder,
  label,
  type,
  disabled = false,
  multiline = false,
  endIcon = null,
  required = false
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          error={!!(errors && errors?.[name] && errors?.[name].message)}
          fullWidth
        >
          <TextField
            label={label}
            placeholder={placeHolder}
            variant="outlined"
            disabled={disabled}
            fullWidth
            required={required}
            size="small"
            {...field}
            type={type === "password" && !showPassword ? "password" : "text"} // Toggle password type
            multiline={multiline}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {type === "password" ? (
                    <IconButton sx={{ p: 0 }} onClick={handleTogglePassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ) : (
                    endIcon
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true, // Forces the label to shrink when there's a value (autofill or manual input)
            }}
            sx={{
              backgroundColor: "#FFFFFF", // Pure white background
              "& .MuiOutlinedInput-root": {
                color: "#000000", // Full black text color
                "& fieldset": {
                  borderColor: "#000000", // Black border color for visibility
                  borderWidth: "1px", // Make the border thicker
                },
                "&:hover fieldset": {
                  borderColor: "#000000", // Keep black border on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#000000", // Keep black border when focused
                },
                "& input": {
                  color: "#000000", // Make sure the input text is black
                }
              },
              "& .MuiInputLabel-root": {
                color: "#000000", // Make the label text black
              },
              "& .MuiFormHelperText-root": {
                color: theme.palette.error.main, // Error text color (optional, if you want to maintain theme colors)
              }
            }}
          />
          <FormHelperText>
            {errors && errors?.[name] && errors?.[name].message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default InputField;
