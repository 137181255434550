import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisitsTable from '../../components/modules/reports/VisitsTable';
import { uiActions } from '../../redux/slice/ui.slice';
import CustomNavTabs from '../../components/shared/NavTabs/CustomNavTabs';
import { userReportItems } from '../../components/modules/user/helper';
import ServedTable from '../../components/modules/reports/ServedTable';

const Reporting = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav("Reporting"));
    }, [dispatch])

    const selectedTab = useSelector(uiActions.selectors.getUserReportTab);

    const handleSelectedTab = (value) => {
        dispatch(uiActions.actions.setUserReportTab(value));
    };

    const renderUserComponent = (selected) => {
        switch (selected) {
            case 0:
                return <VisitsTable />
            case 1:
                return <ServedTable />
            default:
                return <VisitsTable />
        }
    }

    return (
        <div>
            <CustomNavTabs selectedTab={selectedTab} handleSelectedTab={handleSelectedTab} navitems={userReportItems} />
            {renderUserComponent(selectedTab)}
        </div>
    )
}

export default Reporting
