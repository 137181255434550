import apiInterceptor from "./Interceptors/ApiInterceptor";

export const createMemberServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(`/api/create-member`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getMemberServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/api/get-members`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleMemberServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/api/get-single-member`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMemberServ = async (memberId) => {
  try {
    const response = await apiInterceptor.delete(`/api/delete-member/${memberId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};


export const updateMemberServ = async (payload, id) => {
  try {
    const response = await apiInterceptor.put(`/api/update-member/${id}`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const changeMemberStatusServ = async (payload, id) => {
  try {
    const response = await apiInterceptor.put(`/api/change-member-status/${id}`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};