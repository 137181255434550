import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    sideBarOpen: false,
    selectedNav: 'Dashboard',
    userMangementTab: 0,
    userReportTab: 0,
    memberPopup: false
}

const name = 'ui'
const parentSelector = state => state?.[name]

const uiSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetUI: () => {
            return initialState;
        },
        setSelectedNav: (state, action) => {
            return {
                ...state,
                selectedNav: action.payload,
            };
        },
        setUserMangementTab: (state, action) => {
            return {
                ...state,
                userMangementTab: action.payload,
            };
        },
        setUserReportTab: (state, action) => {
            return {
                ...state,
                userReportTab: action.payload,
            };
        },
        setSideBarOpen: (state, action) => {
            return {
                ...state,
                sideBarOpen: action.payload,
            };
        },
        setMemberPopup: (state, action) => {
            return {
                ...state,
                memberPopup: action.payload,
            };
        },
    },
});


const getSelectedNav = createSelector(parentSelector, state => {
    return state?.selectedNav
})

const getUserMangementTab = createSelector(parentSelector, state => {
    return state?.userMangementTab
})

const getUserReportTab = createSelector(parentSelector, state => {
    return state?.userReportTab
})


const getSideBarOpen = createSelector(parentSelector, state => {
    return state?.sideBarOpen
})

const getMemberPopup = createSelector(parentSelector, state => {
    return state?.memberPopup
})

const actions = {
    ...uiSlice.actions
}

const selectors = {
    getSelectedNav,
    getUserMangementTab,
    getSideBarOpen,
    getUserReportTab,
    getMemberPopup
}

export const uiActions = { actions, selectors }


export default uiSlice;