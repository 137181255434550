import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { put, takeEvery } from "redux-saga/effects";
import { loginServ } from "../services/auth.service";
import { authActions } from "../slice/auth.slice";
import {
  USER_LOGIN,
  USER_LOGOUT
} from "../types/auth.types";


export function* loginSaga(action) {
  try {
    const user = yield loginServ(action.payload);  // Call the login service with payload

    if (user && user.data) {  // Check if user data is present
      yield put(authActions.actions.setCurrentUserSlice(user.data));  // Set current user in slice
      yield put(authActions.actions.loginUserStatus(true));  // Update login status to true

      toast.success("Login successful");  // Short success message
      Cookies.set("accessToken", user.data.accessToken, {
        expires: 7,
        secure: true,
      });
    } else {
      toast.error("Login failed");
    }
  } catch (err) {
    toast.error("Unauthorized");
  }
}

export function* logoutSaga() {
  try {
    Cookies.remove("accessToken");
    yield put(authActions.actions.resetAuth());
  } catch (err) {
    console.log("err", err);
  }
}


export function* watchAuthAsync() {
  yield takeEvery(USER_LOGIN, loginSaga);
  yield takeEvery(USER_LOGOUT, logoutSaga);
}
