import apiInterceptor from "./Interceptors/ApiInterceptor";

export const createUserServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(`/auth/create-user`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/auth/get-users`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getServedUserServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/auth/get-users-served`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserServ = async (userId) => {
  try {
    const response = await apiInterceptor.delete(`/auth/delete-user/${userId}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};


export const updateUserServ = async (payload) => {
  try {
    const response = await apiInterceptor.put(`/auth/update-user`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};