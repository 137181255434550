import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sharedActions } from '../../redux/slice/shared.slice';
import { userActions } from '../../redux/slice/user.slice';
import { GET_SINGLE_MEMBER } from '../../redux/types/member.types';
import MemberInfo from '../modules/user/Member/MemberInfo/MemberInfo';
import MyDialog from './MyDialog/MyDialog';

const AppBoot = () => {

    const dispatch = useDispatch();
    const [isModalOpen, setIsModelOpen] = useState(false);

    const handleModelOpen = () => {
        setIsModelOpen(true)
    }

    const handleModelClose = () => {
        setIsModelOpen(false)
        dispatch(userActions.actions.setSelectedMemberSlice({}))
    }

    const cardState = useSelector(sharedActions.selectors.getCardState);



    useEffect(() => {
        const handleCardScanned = ({ detail: id }) => {
            console.log("cardState", cardState)
            if (cardState === 'all') {
                const payload = {
                    cardNumber: id
                };
                dispatch({ type: GET_SINGLE_MEMBER, payload });
                handleModelOpen();
            }
        };
        window.addEventListener('card-scanned', handleCardScanned);

        return () => {
            window.removeEventListener('card-scanned', handleCardScanned);
        };
    }, [cardState, dispatch]);




    const selectedMember = useSelector(userActions.selectors.getSelectedMember)


    return <>
        <MyDialog maxWidth='xl' title={"Member Information"} open={isModalOpen} handleClose={handleModelClose}  >
            <MemberInfo member={selectedMember} handleClose={handleModelClose} />
        </MyDialog>
    </>
}

export default memo(AppBoot)
