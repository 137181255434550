import React, { memo, useState } from 'react';
import toast from 'react-hot-toast';
import SignatureCanvas from 'react-signature-canvas';
import styles from './member.module.scss';
import { Button } from '@mui/material';
import { Clear, Save } from '@mui/icons-material';
import { base64ToBlob } from './helper';

const SignaturePad = ({ handleSignatureSubmit, type }) => {
    const [canvasInstance, setCanvasInstance] = useState(null);

    const clearSignature = () => {
        if (canvasInstance) {
            canvasInstance.clear();
        }
    };

    const saveSignature = () => {
        if (canvasInstance && !canvasInstance.isEmpty()) {
            const image = canvasInstance.toDataURL(); // Get the base64 image from the canvas

            // Convert base64 to Blob (and then to File if needed)
            const file = new File(
                [base64ToBlob(image, 'image/png')],
                `${type}.png`,
                { type: 'image/png' }
            );

            handleSignatureSubmit(type, image, file);
        } else {
            toast.error("Please provide a signature before saving.");
        }
    };


    return (
        <div className={styles.signatureContainer}>
            <SignatureCanvas
                penColor='black'
                canvasProps={{ width: 500, height: 300, className: styles.signatureCanvas }}
                ref={(ref) => setCanvasInstance(ref)}
            />

            <div className={styles.btnContainer}>
                <Button
                    sx={{ p: 1, px: 3 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={saveSignature}

                >
                    Save Signature
                </Button>
                <Button
                    sx={{ p: 1, px: 3 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Clear />}
                    onClick={clearSignature}
                >
                    Clear Signature
                </Button>
            </div>

        </div>
    );
};

export default memo(SignaturePad);
