import { Bookmark, ContentPasteOff, CreditCard, Healing, LocationOn, People, Store, Tour, VerifiedUser } from '@mui/icons-material';
import { Badge, Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs'; // Import dayjs
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ADD_VISIT } from '../../../../../redux/types/shared.types';
import MyDialog from '../../../../shared/MyDialog/MyDialog';
import tableStyles from '../../../../shared/MyTable/myTable.module.scss';
import TableSkeleton from '../../../../shared/MyTable/TableSkeleton';
import { formatDateTime } from '../../../../utils/date';
import styles from '.././member.module.scss';

const MemberInfo = ({ member, handleClose }) => {
    const dispatch = useDispatch();

    const [confirmShopModal, setConfirmShopModal] = useState(false);

    const handleToggleConfirmShop = () => {
        setConfirmShopModal(prevState => !prevState);
    };

    const isRestrictedDay = () => {
        const today = dayjs().format('dddd');
        return !["Tuesday", "Thursday", "Saturday"].includes(today);
    };

    const handleCreateVisit = (confirmShop) => {
        if (confirmShop) {
            const payload = {
                memberId: member?._id,
            };
            dispatch({ type: ADD_VISIT, payload });
            handleClose()
        }
        else {
            if (isRestrictedDay()) {
                setConfirmShopModal(true);
            } else {
                const payload = {
                    memberId: member?._id,
                };
                dispatch({ type: ADD_VISIT, payload });
                handleClose();
            }
        }
    };

    const [loading, setLoading] = useState(true);
    const [noUserFound, setNoUserFound] = useState(false);

    useEffect(() => {
        if (Object.keys(member)?.length) {
            setLoading(false);
            setNoUserFound(false);
        } else {
            const timer = setTimeout(() => {
                setLoading(false);
                setNoUserFound(true);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [member]);

    return (
        <Box className={styles.memberInfoCard}>
            {loading ? (
                <TableSkeleton rows={4} cols={1} />
            ) : noUserFound ? (
                <Box className={tableStyles.noData} sx={{ minHeight: '200px' }}>
                    <ContentPasteOff /> User Not Found
                </Box>
            ) : (
                <Box className={styles.formContainer} >
                    <Grid container spacing={2} alignItems="center">
                        {!member?.userActive && (
                            <Grid item xs={12} textAlign="center" display="flex" justifyContent="center" className={styles.memberHeader}>
                                <Typography color="error" variant="h6">
                                    Your account is inactive. Please activate to continue shopping.
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} textAlign="center" className={styles.memberHeader}>
                            <Typography variant="h5">{`${member?.surnameMember} ${member?.forenameMember}`}</Typography>
                        </Grid>

                        <Grid item xs={6} display="flex" flexDirection="column" gap={1}>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <Bookmark fontSize="14px" className={styles.icon} /> <strong>Last Subscription:</strong> {formatDateTime(member?.subscription?.paidDate)}
                            </Typography>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <Tour fontSize="14px" className={styles.icon} /> <strong>Last Visit:</strong> {formatDateTime(member?.lastVisitDate)}
                            </Typography>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <People fontSize="14px" className={styles.icon} /> <strong>No. of Household:</strong> {member?.totalNumberOfPeople}
                            </Typography>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <LocationOn fontSize="14px" className={styles.icon} /> <strong>No. of Visits (This Week):</strong> {member?.visitCounts}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} display="flex" flexDirection="column" gap={1}>
                            <Box className={styles.infoItem}>
                                <VerifiedUser fontSize="14px" className={styles.icon} />
                                <Typography variant="subtitle1" className={styles.infoItem}>User Status:</Typography>
                                <Badge sx={{ ml: 4 }} badgeContent={member?.userActive ? "Active" : "Inactive"} color={member?.userActive ? "success" : "error"} />
                            </Box>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <CreditCard fontSize="14px" className={styles.icon} /> Card Number: {member?.cardNumber}
                            </Typography>
                            <Typography variant="subtitle1" className={styles.infoItem}>
                                <Healing fontSize="14px" className={styles.icon} /> Allergy Details: {member?.allergyDetails}
                            </Typography>
                            <Box className={styles.infoItem}>
                                <VerifiedUser fontSize="14px" className={styles.icon} />
                                <Typography variant="subtitle1" className={styles.infoItem}>Proof of ID:</Typography>
                                <Badge sx={{ ml: 4 }} badgeContent={member?.proofOfId ? "True" : "False"} color={member?.proofOfId ? "success" : "error"} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
                            <Typography variant="subtitle1" className={styles.infoItem}>Photos</Typography>
                            <Grid container spacing={2}>
                                {[member?.photo1].map((photo, index) =>
                                    photo && (
                                        <Grid item xs={3} key={index}>
                                            <img src={photo} alt={`Preview ${index + 1}`} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>

                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <Button
                                sx={{ p: 1, px: 3 }}
                                variant="contained"
                                color="primary"
                                startIcon={<Store />}
                                type="submit"
                                disabled={!member?.userActive}
                                onClick={() => handleCreateVisit(false)}
                            >
                                Confirm Shop/Visit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <MyDialog maxWidth='sm' title={"Member Information"} open={confirmShopModal} handleClose={handleToggleConfirmShop}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                    <Typography variant="h6" textAlign="center">
                        {`Shopping is restricted on ${dayjs().format('dddd')}. Do you still wish to proceed?`}
                    </Typography>
                    <Box mt={3} display="flex" justifyContent="space-between" width="100%">
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleToggleConfirmShop}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setConfirmShopModal(false);
                                handleCreateVisit(true);
                            }}
                            startIcon={<Store />}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </MyDialog>
        </Box>
    );
};

export default MemberInfo;
